/*** loopingvideo.js ***/
(function($, window, document, undefined) {
  'use strict';

  var i,
  loopingVideos = document.getElementsByClassName('looping-video'),
  // Obtain user agents
  device = {
    isAndroid: function() {
      return navigator.userAgent.match(/Android/i);
    },
    isIphone: function() {
      return navigator.userAgent.match(/iPhone|iPod/i);
    },
    isIpad: function() {
      return navigator.userAgent.match(/iPad/i);
    },
    isMobile: function() {
      return navigator.userAgent.match(/Android|Blackberry|iPhone|iPad|iPod|IEMobile/i);
    }
  };
  // On desktop, play only when video is in viewport
  if ( !device.isMobile() ) {
    window.addEventListener('scroll', function(event) {
      var wh = window.innerHeight,
          tolerance = 0.50;
      for (i = 0; i < loopingVideos.length; i++) {
        var v = loopingVideos[i],
            rect = v.getBoundingClientRect(),
            tolerance_v = (rect.bottom - rect.top) * tolerance;
        if (rect.bottom < tolerance_v || (wh - rect.top) < tolerance_v ) {
          if (!v.paused) {
            v.pause();
          }
        } else {
          if (v.paused) {
            v.play();
          }
        }
      }
    });
  }
  // DOM ready
  $(function() {
    // Init CanvasVideoPlayer (for inline video on iPhone/iPod)
    if ( device.isIphone() ) {
      for (i = 0; i < loopingVideos.length; i++) {
        loopingVideos[i].id = 'lv-video' + i;
        loopingVideos[i].nextSibling.id = 'lv-canvas' + i;
        new CanvasVideoPlayer({
          videoSelector: '#lv-video' + i,
          canvasSelector: '#lv-canvas' + i
        });
      }
    }
    // Disable autoplay on mobile
    if ( device.isMobile() ) {
      for (i = 0; i < loopingVideos.length; i++) {
        var v = loopingVideos[i];
        v.removeAttribute('autoplay');
      }
    }
    // Custom play/pause button for Android and iPad
    // TODO: Use vanilla JS?
    if ( device.isAndroid() || device.isIpad() ) {
      $('.lv-container').each(function() {
        var video = $(this).find('video'),
            videoControls = $(this).find('.lv-controls'),
            playpause = $(this).find('.playpause');
        video.controls = false;
        videoControls.css('display', 'block');
        video.add(playpause).click(function() {
          if (video.get(0).paused || video.get(0).ended) {
            video.get(0).play();
            playpause.css('opacity', 0);
          } else {
            video.get(0).pause();
            playpause.css('opacity', 1);
          }
        });
      });
    }
  });
})(jQuery, window, document);
