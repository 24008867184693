var setupVideo = function ($video, videoType) {
  $('.controls .browse.next').removeClass('disabled');

  var is_firefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  var me_mode = 'auto';
  if (is_firefox) {
    me_mode = 'auto_plugin';
  }
  new MediaElementPlayer($video, {
    type: videoType,
    mode: me_mode,
    startVolume: 0.8,
    AndroidUseNativeControls: true,
    iPhoneUseNativeControls: true,
    iPadUseNativeControls: true,
    pluginPath: '//d4xmq39929kw8.cloudfront.net/mediaelement-2.14/',
    plugins: ['flash'],
    flashName: 'flashmediaelement-cdn.swf'
  });
};

var fetchGAKeys = function () {
  var bodyElement = document.querySelector('body');
  if (!bodyElement) {
    return;
  }

  // "{"ids":["UA-128370714-1"],"lucid_id":"UA-77563-16"}"
  // {"ids":["UA-128370714-1","UB-123456-1"],"lucid_id":"UA-77563-16"}
  var gaKeyData = bodyElement.dataset.googleAnalytics;
  if (!gaKeyData) {
    return;
  }

  return JSON.parse(gaKeyData).ids;
};

var setupGA = function (video) {
  if (typeof(ga) == 'undefined') {
    return;
  }

  gaKeys = fetchGAKeys();
  if (!gaKeys) {
    return;
  }

  for (var i = 0; i < gaKeys.length; i++) {
    ga('create', gaKeys[i], 'auto');
  }

  var videoSrc = video.getAttribute('src');
  var eventTypes = ['play', 'pause', 'ended'];
  for (var i = 0; i < eventTypes.length; i++) {
    video.addEventListener(eventTypes[i], function(event) {
      ga('send', 'event', 'Video', event.type, videoSrc);
    });
  }
};

$(function () {
  var $video = $('#video-player');
  if (!$video.length) {
    return;
  }
  var videoType = $video.data('type');

  setupVideo($video, videoType);
  setupGA($video[0]);
});
