var dot = function (slider, i) {
  return '<a class="dot-link" type="button" data-role="none" ' +
         'role="button" aria-required="false" tabindex="0"></a>';
};

$(function () {
  $('#image-sets').slick({
    slide: '.image-set',
    dots: true,
    dotsClass: 'dot',
    prevArrow: $('#previous-arrow'),
    nextArrow: $('#next-arrow'),
    infinite: false,
    customPaging: function (slider, i) {
      return dot(slider, i);
    }
  });
});
