var lucidUnlocked = function () {
  return $('html').hasClass('xlucid_unlocked');
};

var overlayOpen = function () {
  return $('.page-overlay').hasClass('active');
};

function VideoOverlay(id) {
  this.id = id;
  this.video = null;

  this.init = function() {
    var elem = document.getElementById(this.id);
    if (!elem) return;

    var videoType = 'video/mp4';
    var features = ['playpause', 'current', 'progress', 'duration', 'tracks',
                    'volume', 'fullscreen'];

    this.video = new MediaElementPlayer(elem, {
      type: videoType,
      features: features,
      AndroidUseNativeControls: true,
      iPhoneUseNativeControls: true,
      iPadUseNativeControls: true,
      success: function (mediaElement) {
        mediaElement.addEventListener('ended', function(e) {
          var cta = document.querySelector('.after-play-wrapper');
          if (cta) cta.classList.add('active');
        });
        mediaElement.addEventListener('webkitendfullscreen', function() {
          window.history.back();
        });
      }
    });

    return this.video;
  }

  this.play = function() {
    if (!this.video) return;

    return this.video.play();
  }

  this.pause = function() {
    if (!this.video) return;

    return this.video.pause();
  }

  this.init();
}

$(function() {
  var $videoOverlay = new VideoOverlay('video-overlay-player');

  var closePageOverlay = function () {
    $('.page-overlay').removeClass('active');
    $.scrollLock();
    if ($videoOverlay) {
      $videoOverlay.pause();
    }
  };

  var addPushState = function () {
    var scrollTop = $('body').scrollTop();

    history.replaceState({scrollTop: scrollTop}, null, null);
    history.pushState({overlay: 'open'}, null, null);
  };

  var initPageOverlayCloser = function () {
    window.onpopstate = function (event) {
      if (!overlayOpen()) {
        return;
      }

      var scrollTop = event.state.scrollTop;

      closePageOverlay();
      if (scrollTop) {
        $('body').scrollTop(scrollTop);
      }
    };

    $(document).on('keyup', function (e) {
      if (!overlayOpen() || e.keyCode !== 27) return;

      closePageOverlay();
    });
  };

  var activateOverlay = function ($elem) {
    $elem.toggleClass('active');
    addPushState();
    $.scrollLock();
    initPageOverlayCloser();
  };

  $('.menu-link').on('click', function () {
    activateOverlay($('#page-menu-overlay'));
  });

  $('.page-overlay-close-link').on('click', function () {
    window.history.back();
  });

  $('#mobile-page-menu-link').on('click', function () {
    activateOverlay($('#page-menu-overlay'));
  });

  $('#blog-archive-link').on('click', function () {
    activateOverlay($('#blog-archive-overlay'));
  });

  $('#blog-tags-link').on('click', function () {
    activateOverlay($('#blog-tags-overlay'));
  });

  $('.phone-overlay-link').on('click', function () {
    activateOverlay($('#phone-overlay'));
  });

  $('.location-overlay-link').on('click', function () {
    activateOverlay($('#location-overlay'));
  });

  $('.contact-overlay-link').on('click', function () {
    var $overlay = $('#contact-overlay');
    activateOverlay($overlay);
    initExpandingTextareas($overlay);
  });

  $('.ppc-cta-button').on('click', function () {
    var $overlay = $('#ppc-contact-overlay');
    activateOverlay($overlay);
    initExpandingTextareas($overlay);
  });

  $('.social-accounts-overlay-link').on('click', function () {
    activateOverlay($('#social-accounts-overlay'));
  });

  if ($videoOverlay) {
    var cta = document.querySelector('.after-play-wrapper');

    $('.video-overlay-link').on('click', function () {
      var $overlay = $('#video-overlay');
      activateOverlay($overlay);
      $videoOverlay.play()
      if (cta) cta.classList.remove('active');
    });

    $('.video-overlay-replay-link').on('click', function () {
      $videoOverlay.play()
      if (cta) cta.classList.remove('active');
    });
  }

  ////
  // Fixed menu
  var $window = $(window);
  var $header = $('#page-header');
  var $simpleHeader = $('#simple-header');
  var $heroHeader = $('#hero-header');
  var $fixedHeader = $('#fixed-header');
  var $mobileMenu = $('#mobile-menu');
  var bottomOfMenuHeight = 0;
  var menuHeight = 0;
  var $menu;

  if ($simpleHeader.length) {
    bottomOfMenuHeight = $simpleHeader.height();
  } else if ($heroHeader.length) {
    bottomOfMenuHeight = $heroHeader.height();
  }

  if ($mobileMenu.is(':visible')) {
    menuHeight = $mobileMenu.height();
  }

  // CANTFIX: improve transition on iPads
  // touch instantly adds nav.  CSS transition is ignored
  $window.on('scroll touchmove', function () {
    if ($window.scrollTop() >= bottomOfMenuHeight) {
      $fixedHeader.addClass('active');
      return true;
    }

    if (!overlayOpen()) {
      $fixedHeader.removeClass('active');
    }

    return true;
  });

  $window.scroll();
});
